
import { defineComponent } from 'vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmPerson,
    TmAvatar,
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const assignee = {
      avatarColor: 'orange',
      fullName: 'John Doe',
    }

    return {
      assignee,
      isSmMax,
    }
  },
})
