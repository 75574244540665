
import { computed, defineComponent, ref } from 'vue'
import HeaderNew from '@/components/layout/header/HeaderNew.vue'
import HeaderModeTool from '@/components/layout/header/HeaderModeTool.vue'
import HeaderTabs from '@/components/layout/header/tabs/HeaderTabs.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import TmButton from '@/components/shared/TmButton.vue'
import TmBadgeCounter from '@/components/shared/TmBadgeCounter.vue'
import TmSearch from '@/components/shared/TmSearch.vue'

export default defineComponent({
  components: {
    TmBadgeCounter,
    TmButton,
    TmSearch,
    HeaderModeTool,
    HeaderTabs,
    HeaderNew,
  },
  props: {
    hideMode: {
      type: Boolean,
    },
  },
  setup(props) {
    const { isMdMax } = useBreakpoints()
    const isDemoStand = window.location.host === 'demo.textmagic.com'
    const isModeVisible = computed(() => {
      return !isMdMax.value && !props.hideMode && !isDemoStand
    })
    const search = ref('')

    return {
      isModeVisible,
      search,
    }
  },
})
