import type { ComputedRef } from 'vue'
import { computed, ref } from 'vue'
import { onBeforeRouteUpdate } from 'vue-router'

type UseMobileNavigationType = {
  updateProfileState: (value?: boolean) => void;
  updateMenuState: (value?: boolean) => void;
  closeNavigation: () => void;
  getProfileState: ComputedRef<boolean>;
  getMenuState: ComputedRef<boolean>;
  getOverlayState: ComputedRef<boolean>;
}

const isProfileVisible = ref(false)
const isMenuVisible = ref(false)

export const useMobileNavigation = (): UseMobileNavigationType => {
  const getProfileState = computed(() => isProfileVisible.value)
  const getMenuState = computed(() => isMenuVisible.value)
  const getOverlayState = computed(() => isProfileVisible.value || isMenuVisible.value)

  const updateProfileState = (value?: boolean) => {
    if (typeof value === 'boolean') {
      isProfileVisible.value = value
    } else {
      isProfileVisible.value = !isProfileVisible.value
    }

    isMenuVisible.value = false
  }

  const updateMenuState = (value?: boolean) => {
    if (typeof value === 'boolean') {
      isMenuVisible.value = value
    } else {
      isMenuVisible.value = !isMenuVisible.value
    }

    isProfileVisible.value = false
  }
  const closeNavigation = () => {
    updateMenuState(false)
    updateProfileState(false)
  }

  onBeforeRouteUpdate(() => {
    closeNavigation()
  })

  return {
    updateProfileState,
    updateMenuState,
    closeNavigation,
    getProfileState,
    getMenuState,
    getOverlayState,
  }
}
