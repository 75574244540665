
import { computed, defineComponent, ref, nextTick } from 'vue'
import { useTabs } from '@/compositions/tabs'
import { useRoute, useRouter } from 'vue-router'
import type { RouteLocationRaw } from 'vue-router'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import HeaderTab from '@/components/layout/header/tabs/HeaderTab.vue'

export default defineComponent({
  components: {
    HeaderTab,
    TmAutoSizePanel,
  },
  setup() {
    const autoSize = ref()
    const {
      tabsList,
      removeTab,
      openTab,
      activeTabIndex,
      getTabMessenger,
      getHiddenItemsCount,
      setHiddenItemsCount,
    } = useTabs()
    const router = useRouter()
    const route = useRoute()

    const btnMoreTabs = computed(
      () => `+${getHiddenItemsCount.value} ${getHiddenItemsCount.value > 1 ? 'tabs' : 'tab'}`
    )

    const closeTab = (index: number) => {
      const isTabActive = index === activeTabIndex.value
      const currentTab = tabsList.value[index]
      removeTab(index)

      nextTick(() => {
        autoSize.value.updateVisibleChildren()
      })

      if (route.name !== currentTab.name) { return }

      if (tabsList.value.length) {
        if (isTabActive) {
          const nextTab = tabsList.value[index - 1]
            ? tabsList.value[index - 1]
            : tabsList.value[index]

          router.push({ name: nextTab?.name, params: nextTab.params })
        }
        return
      }

      const getParentRoute = (tab: any): RouteLocationRaw => {
        switch (tab) {
          case 'ticket': return { name: 'base.tickets.main' }
          case 'contact_segment': return { name: 'base.contacts.segments' }
          case 'contact_list': return { name: 'base.contacts.lists' }
          case 'contact_organization': return { name: 'base.contacts.organizations' }
          case 'contact': return { name: 'base.contacts' }
          default: return '/'
        }
      }

      router.push(getParentRoute(currentTab?.meta.tab))
    }

    return {
      closeTab,
      openTab,
      tabsList,
      autoSize,
      activeTabIndex,
      btnMoreTabs,
      getTabMessenger,
      setHiddenItemsCount,
    }
  },
})
