import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d0ed1cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "header-profile-button"
}
const _hoisted_2 = { class: "neutral--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_avatar = _resolveComponent("tm-avatar")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isSmMax)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_tm_person, {
            name: _ctx.assignee.fullName,
            "avatar-color": _ctx.assignee.avatarColor,
            "semi-bold": "",
            "avatar-size": "large",
            "avatar-status": "online"
          }, {
            name: _withCtx(({ name }) => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(name), 1)
            ]),
            _: 1
          }, 8, ["name", "avatar-color"]),
          _createVNode(_component_tm_icon, {
            name: "tmi-arrow-drop-down-sharp",
            class: "neutral--text"
          })
        ]))
      : (_openBlock(), _createBlock(_component_tm_avatar, {
          key: 1,
          name: _ctx.assignee.fullName,
          color: _ctx.assignee.avatarColor,
          "custom-size": "32px",
          class: "pointer"
        }, null, 8, ["name", "color"]))
  ]))
}