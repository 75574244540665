<template>
  <div class="content-wrapper">
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
.content-wrapper {
  position: relative;
  z-index: 10;
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow: hidden;
  height: 100%;
  background: $white;
}
</style>
