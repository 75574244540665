
import { computed, defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { modesSettings, useModes } from '@/compositions/modes'

export default defineComponent({
  components: { TmButton, TmDropdownItem, TmDropdown },
  setup() {
    const { setMode, getMode } = useModes()
    const currentMode = getMode()
    const currentModeShortLabel = computed(() => modesSettings[currentMode.value].labelShort)
    return {
      setMode,
      currentMode,
      currentModeShortLabel,
      modesSettings,
    }
  },
})
