
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useModals } from '@/compositions/modals'
import useTemplatesOpenModal from '@/compositions/templates/useTemplatesOpenModal'
import useSmsSurvey from '@/compositions/services/useSmsSurvey'
import { useDropUps } from '@/compositions/dropups'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmButton,
    TmDropdown,
  },
  props: {
    outline: {
      type: Boolean,
    },
  },
  setup() {
    const { openModal } = useModals()
    const { openModalTemplate } = useTemplatesOpenModal()
    const { openNewModal } = useSmsSurvey()
    const { showDropUp } = useDropUps()

    return {
      openNewModal,
      openModalTemplate,
      openModal,
      showDropUp,
    }
  },
})
