
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import type { PropType } from 'vue'
import { getRecord, getTableData } from '@/services/tableService'
import type { tabObj } from '@/compositions/tabs'
import { tmAutoSizePanelParentClass } from '@/definitions/shared/autoSize/data'

type Tab = {
  icon: string;
  title: any;
  text: any;
  mock: string;
}
type Tabs = {
  [key: string]: Tab;
}

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
    },
    tabId: {
      type: String,
      required: true,
    },
    tabType: {
      type: String,
      default: 'ticket',
    },
    tabMessenger: {
      type: Object as PropType<tabObj>,
    },
    tab: {
      type: Object as PropType<tabObj>,
    },
  },
  emits: ['openTab', 'closeTab'],
  setup(props) {
    const tabEl = ref()
    const isInDropdown = computed(() => !!tabEl.value?.closest(`.${tmAutoSizePanelParentClass}`))
    const tabTypes: Tabs = {
      ticket: {
        icon: 'mail',
        title: (obj: any) => obj?.subject,
        text: (obj: any) => obj?.requester.fullName,
        mock: 'tickets',
      },
      contact: {
        icon: 'person',
        title: (obj: any) => `${obj?.person.lastName} ${obj?.person.firstName}`,
        text: () => 'Contact',
        mock: 'contactsAllContacts',
      },
      contact_organization: {
        icon: 'business',
        title: (obj: any) => obj?.organization.organizationName,
        text: (obj: any) => `${obj?.contacts} contacts`,
        mock: 'contactsOrganizations',
      },
      contact_list: {
        icon: 'tmi-people',
        title: (obj: any) => obj?.listName,
        text: (obj: any) => `${obj?.contacts} contacts`,
        mock: 'contactsLists',
      },
      contact_segment: {
        icon: 'pie_chart',
        title: (obj: any) => obj?.segmentName,
        text: (obj: any) => `${obj?.contacts} contacts`,
        mock: 'contactsSegments',
      },
      messenger: {
        icon: 'chat',
        title: () => props.tabMessenger?.meta?.title || 'Messenger',
        text: () => {
          const num = Math.floor(Math.random() * 10)
          const message = ` ${num === 1 ? 'chat' : 'chats'}`
          return num + message
        },
        mock: 'contactsSegments',
      },
    }

    const post = ref()
    const title = ref()
    const text = ref()
    const getPost = () => {
      post.value = getRecord(tabTypes[props.tabType].mock, props.tabId) || getTableData(tabTypes[props.tabType].mock)[0]
      title.value = tabTypes[props.tabType as keyof Tab].title(post.value)
      text.value = tabTypes[props.tabType as keyof Tab].text(post.value)
    }

    onMounted(() => getPost())

    watch(() => props.tabId, () => getPost())

    return {
      tabTypes,
      title,
      text,
      post,
      tabEl,
      isInDropdown,
    }
  },
})
