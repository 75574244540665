import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-190a3564"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-tabs__more-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_tab = _resolveComponent("header-tab")!
  const _component_tm_auto_size_panel = _resolveComponent("tm-auto-size-panel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["header-tabs", {'header-tabs--empty': !_ctx.tabsList.length}])
  }, [
    _createVNode(_component_tm_auto_size_panel, {
      ref: "autoSize",
      "btn-offset-left": false,
      "dropdown-items-hidden-class": "",
      "onUpdate:hiddenItemsCount": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setHiddenItemsCount($event)))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabsList, (tab, index) => {
          return (_openBlock(), _createBlock(_component_header_tab, {
            key: index,
            "tab-id": tab.params.id || tab.name,
            "is-active": index === _ctx.activeTabIndex,
            "tab-type": tab.meta['tab'],
            "tab-messenger": _ctx.getTabMessenger,
            tab: tab,
            onCloseTab: ($event: any) => (_ctx.closeTab(index)),
            onOpenTab: ($event: any) => (_ctx.openTab(tab, index))
          }, null, 8, ["tab-id", "is-active", "tab-type", "tab-messenger", "tab", "onCloseTab", "onOpenTab"]))
        }), 128))
      ]),
      "dropdown-activator": _withCtx(() => [
        _createElementVNode("button", _hoisted_1, _toDisplayString(_ctx.btnMoreTabs), 1)
      ]),
      "dropdown-after-permanent": _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(_ctx.tabsList)), undefined, true)
      ]),
      _: 3
    }, 512)
  ], 2))
}