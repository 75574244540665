import { computed, onMounted, ref, watch } from 'vue'
import type { ComputedRef } from 'vue'
import type { LocationQuery, RouteMeta, RouteParams, RouteRecordName } from 'vue-router'
import { useRoute } from 'vue-router'

export type tabObj = {
  params: RouteParams;
  query: LocationQuery;
  name: RouteRecordName | undefined;
  meta: RouteMeta;
}
type tabAction = (tab: tabObj) => void
type tabActionWithIndex = (tab: tabObj, index: number) => void
type tabActionByIndex = (index: number) => void
type tabReplace = (indexDelete: number, indexLastVisible: number, addElem: tabObj) => void

type UseTabsVal = {
  openTab: tabActionWithIndex;
  removeTab: tabActionByIndex;
  tabsList: ComputedRef<tabObj[]>;
  getTabMessenger: ComputedRef<tabObj | undefined>;
  currentTab: ComputedRef<tabObj>;
  activeTabIndex: ComputedRef<number>;
  getHiddenItemsCount: ComputedRef<number>;
  setHiddenItemsCount: tabActionByIndex;
}

const tabMessenger = ref<tabObj | undefined>()
const hiddenItemsCount = ref<number>(0)
const tabs = ref<tabObj[]>([])

export const useTabs = (): UseTabsVal => {
  const route = useRoute()

  const tabsList = computed(() => tabs.value)
  const getTabMessenger = computed(() => tabMessenger.value)
  const tabType = computed(() => route?.meta?.tab)
  const lastVisibleIndex = computed(() => (tabsList.value.length - 1) - getHiddenItemsCount.value)

  const currentTab = computed(() => ({
    params: route.params,
    query: route.query,
    name: route.name || undefined,
    meta: route.meta,
  }))

  const activeTabIndex = computed(() => {
    if (currentTab.value.meta.tab === 'messenger') {
      return tabsList.value.findIndex(
        (tab) => tab.meta.tab === 'messenger'
      )
    } else {
      if (!currentTab.value.params.id) { return -1 }

      return tabsList.value.findIndex(
        (tab) => tab.params.id === currentTab.value.params.id
      )
    }
  })

  const getHiddenItemsCount = computed(() => hiddenItemsCount.value)
  const setHiddenItemsCount = (val: number) => {
    hiddenItemsCount.value = val
  }

  const checkTab = () => {
    if (tabType.value) {
      addTab(currentTab.value)
    }
  }

  watch(getHiddenItemsCount, () => {
    if (activeTabIndex.value > lastVisibleIndex.value) {
      replaceTab(activeTabIndex.value, lastVisibleIndex.value, tabsList.value[activeTabIndex.value])
    }
  })

  watch(currentTab, () => {
    checkTab()
  })

  onMounted(() => {
    checkTab()
  })

  const removeTab: tabActionByIndex = (index) => {
    tabs.value.splice(index, 1)
  }
  const replaceTab: tabReplace = (indexDelete, indexLastVisible, addElem) => {
    removeTab(indexDelete)
    tabs.value.splice(indexLastVisible, 0, addElem)
  }

  const openTab: tabActionWithIndex = (tab, index) => {
    if (index > lastVisibleIndex.value) {
      replaceTab(index, lastVisibleIndex.value, tab)
    }
  }

  const addTab: tabAction = (tab) => {
    if (tab.meta.tab === 'messenger') {
      tabMessenger.value = tab
      const prevMessengerTab = tabsList.value.findIndex(item => item.meta.tab === 'messenger')
      if (prevMessengerTab > -1) {
        replaceTab(prevMessengerTab, prevMessengerTab, tab)
        return
      }
    } else {
      const sameTabById = tabs.value.find(item => item.params.id === tab.params.id)
      if (sameTabById) return
    }
    tabs.value.push(tab)
  }

  return {
    removeTab,
    openTab,
    tabsList,
    getTabMessenger,
    currentTab,
    activeTabIndex,
    getHiddenItemsCount,
    setHiddenItemsCount,
  }
}
