import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f93f99a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-mode-tool" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_dropdown, null, {
      menu: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modesSettings, (mode, name) => {
          return (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: name,
            label: mode.label,
            class: _normalizeClass(["mode-tool__dropdown-item", { '_active': _ctx.currentMode === name }]),
            onClick: ($event: any) => (_ctx.setMode(name))
          }, null, 8, ["label", "class", "onClick"]))
        }), 128))
      ]),
      default: _withCtx(() => [
        _createVNode(_component_tm_button, { class: "btn-activator" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.currentModeShortLabel), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}