import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ac79ecf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-tabs__tab-left" }
const _hoisted_2 = { class: "header-tabs__tab-content" }
const _hoisted_3 = {
  key: 0,
  class: "header-tabs__tab-content-title"
}
const _hoisted_4 = {
  key: 1,
  class: "header-tabs__tab-content-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "tabEl",
    class: _normalizeClass(["header-tabs__tab", {
      'header-tabs__tab--active': _ctx.isActive,
      'header-tabs__tab--in-dropdown': _ctx.isInDropdown
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_tm_icon, {
        class: "header-tabs__tab-icon",
        size: "medium",
        name: _ctx.tabTypes[_ctx.tabType].icon
      }, null, 8, ["name"]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        (_ctx.text)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.text), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_router_link, {
      to: _ctx.tab,
      class: "header-tabs__tab-overlay",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('openTab')))
    }, null, 8, ["to"]),
    _createElementVNode("div", {
      class: "header-tabs__tab-close",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeTab')))
    }, [
      _createVNode(_component_tm_icon, {
        size: "medium",
        name: "close"
      })
    ])
  ], 2))
}