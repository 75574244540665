import type { ComputedRef } from 'vue'
import { ref, computed } from 'vue'
import { senderSettingsOptions } from '@/definitions/_general/_data/optionsList'
import type { CustomDropUpName, DropUpName, DropUpValueType } from '@/definitions/_layout/dropUps/types'

type DropUpDataType = {
  title: string;
  value: DropUpValueType;
  fromOptions: string[] | any;
}

type UseDropUpsVal = {
  getActiveDropUps: () => ComputedRef<(DropUpName | CustomDropUpName)[]>;
  showDropUp: (name: DropUpName | CustomDropUpName) => void;
  closeDropUp: (name: DropUpName | CustomDropUpName) => void;
  getDropUpData: (name: DropUpName) => DropUpDataType;
}

const activeDropUps = ref<(DropUpName | CustomDropUpName)[]>([])

const dropUpsData = ref<Record<DropUpName, DropUpDataType>>({
  textMessage: {
    title: 'New text message',
    value: {
      from: { label: 'Default sender settings (recommended)' },
      to: [],
      message: '',
      schedule: '',
    },
    fromOptions: [
      ...senderSettingsOptions,
    ],
  },
  email: {
    title: 'New email',
    value: {
      from: 'John Doe <support@company.com>',
      to: [],
      cc: [],
      bcc: [],
      subject: '',
      message: '',
      schedule: '',
    },
    fromOptions: [
      'John Doe <support@company.com>',
      'Alice Brown <abrown@company.com>',
      'USA Support Team <usasupport@company.com>',
      'John Doe <support@company.com>',
      'Alice Brown <abrown@company.com>',
      'USA Support Team <usasupport@company.com>',
      'John Doe <support@company.com>',
      'Alice Brown <abrown@company.com>',
      'USA Support Team <usasupport@company.com>',
    ],
  },
})

export const useDropUps = ():UseDropUpsVal => {
  const getActiveDropUps = ():ComputedRef<(DropUpName | CustomDropUpName)[]> => computed(() => activeDropUps.value)

  const showDropUp = (name: DropUpName | CustomDropUpName): void => {
    if (!activeDropUps.value.includes(name)) {
      activeDropUps.value.push(name)
    }
  }

  const closeDropUp = (name: DropUpName | CustomDropUpName): void => {
    activeDropUps.value = activeDropUps.value.filter((item) => item !== name)
  }

  const getDropUpData = (name: DropUpName): DropUpDataType => {
    return dropUpsData.value[name]
  }

  return {
    getActiveDropUps,
    showDropUp,
    closeDropUp,
    getDropUpData,
  }
}
